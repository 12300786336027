<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>年审信息</el-breadcrumb-item>
      <el-breadcrumb-item>年审信息录入</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card class="box-card">
      <el-button type="primary" @click="opentan()">添加信息</el-button>

      <!-- 添加弹窗 -->

      <el-dialog title="年审信息录入" :visible.sync="dialogVisible" width="40%" :before-close="handleClose">
        <el-form ref="dform" :model="dform" label-width="100px">
          <el-form-item label="车辆" prop="use_type" label-width="150px">
            <el-autocomplete
              :trigger-on-focus="false"
              placeholder="请输入内容"
              v-model="key"
              :fetch-suggestions="salespersonss"
              value-key="auto_number"
              @select="XiaohandleSelectss($event, '车牌号')"
            ></el-autocomplete>
          </el-form-item>
          <el-form-item label="年审费用" prop="use_type" label-width="150px">
            <div class="el-select"><el-input v-model="moneys" placeholder="请输入内容" @input="zhuan"></el-input></div>
          </el-form-item>
          <el-form-item label="年审日期" prop="use_type" label-width="150px">
            <el-date-picker value-format="yyyy-MM-dd" v-model="dform.check_time_at" type="date" placeholder="选择日期"></el-date-picker>
          </el-form-item>
          <el-form-item label="下次年审日期" prop="use_type" label-width="150px">
            <el-date-picker value-format="yyyy-MM-dd" v-model="dform.next_time_at" type="date" placeholder="选择日期"></el-date-picker>
          </el-form-item>
          <el-form-item label="年审附件" prop="icon" label-width="150px">
            <el-upload
              accept=".pdf, .jpg, .jpeg, .png"
              class="upload-demo"
              action=""
              :on-preview="handlePreview"
              :on-remove="
                (file, fileList) => {
                  handleRemove(file, fileList, this.refueling_filess)
                }
              "
              :http-request="httpRequest"
              :multiple="false"
              :limit="1"
              :on-exceed="handleExceed"
              :file-list="refueling_filess"
            >
              <el-button :disabled="addimgtype" size="small" type="primary" @click="upyupefun('年审附件')">点击上传</el-button>
            </el-upload>
          </el-form-item>
          <el-row type="flex" class="row-bg" justify="center">
            <el-form-item>
              <el-button @click="cancelfun()">取 消</el-button>
              <el-button type="primary" @click="submitForm('dform')">添加</el-button>
            </el-form-item>
          </el-row>
          {{ dform }}
        </el-form>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import { Upimg, Daochu } from '@/api/upimg'
import { GetautoList } from '../../../api/car'
import {Gettian} from '../../../api/nianshen'
export default {
  data() {
    return {
      moneys: '',
      addimgtype: false, // 上传控制
      dialogVisible: false, // 对话框
      key: '',
      dform: {
        auto_id: '', // 车辆id
        money: '', // 年审费用（分）
        check_time_at: '', // 审核日期
        next_time_at: '', // 下次审核日期
        from_source: 'mobile',
        files: ''
      },
      refueling_filess: []
    }
  },
  mounted() {},
  methods: {
    // 元转分
    zhuan(e) {
      this.dform.money = e * 100
    },
    // 打开弹窗
    opentan() {
      this.dialogVisible = true
    },
    // 关闭添加弹窗
    handleClose() {
      this.dialogVisible = false
      this.dform = {
        // files:'' // 保单附件
      }
      this.refueling_filess = []
      this.key = ''
      this.moneys = ''
    },
    salespersonss(queryString, cb) {
      this.getallfun(queryString, cb)
    },
    // 搜索车辆选中
    XiaohandleSelectss(e, term) {
      this.dform.auto_id = e.id
    },
    // 上传
    handleChange(file, fileList) {
      console.log(file, fileList)
      // this.fileList = fileList.slice(-3)
    },
    // 添加取消
    cancelfun() {
      this.dform = {}
      this.key = ''
      this.moneys = ''
      this.refueling_filess = []
      this.dialogVisible = false
    },
       // 添加数据
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          // 成功
          this.Gettianjia(this.dform) // 添加用户信息
          // 添加成功之后重新拉取列表
          this.$refs[formName].resetFields()
        }
      })
    },

    // 删除图片
    handleRemove(file, fileList, re) {
      // re.forEach((item, i) => {
      //   if (item.uid === file.uid) {
      //     re.splice(i, 1)
      //   }
      // })
      this.dform.files = ''
      this.refueling_filess = []
    },
    // eslint-disable-next-line no-unused-vars
    handlePreview(file) {},
    // eslint-disable-next-line no-unused-vars
    handleExceed(files, fileList) {
      // this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    // 切换上传条件
    upyupefun(v) {
      this.steupdata = v
    },
    // 图片上传
    async httpRequest(options) {
      console.log(options)
      this.addimgtype = true
      const fd = new FormData()
      fd.append('file[]', options.file)
      const res = await Upimg(fd)
      console.log(res.data[0].url)
      switch (this.steupdata) {
        case '年审附件':
          this.dform.files = res.data[0].url
          console.log(this.dform.files)
          break
      }
      this.addimgtype = false
      // // 图片上传成功后把图片push到scroll中用于修改
      // console.log(res)
    },

    // 请求区
    async getallfun(key, cd) {
      // 获取车辆
      const { data } = await GetautoList({ key: key, page: 1, size: 100 })
      this.userlist = data.data.list
      cd(data.data.list)
    },

    // 年审添加
    async Gettianjia(v){
      const {data} = await Gettian(v)
      this.getanpostfun(data, '添加')
    },



      getanpostfun(data, text, fun) {
      if (data.code === 200) {
        this.$message.success(`${text}成功`)
        // console.log(text)
        if (text !== '获取' && text !== '删除') {
          // console.log(123)
          this.handleClose()
        }

        fun
      } else {
        return this.$message.error(`${text}失败,原因：${data.message}`)
      }
    }
  }
}
</script>

<style>
</style>